.payment-info {
  max-width: 545px !important;
  padding: 40px 10px;

  @media (max-width: 600px) {
    padding: 0px;
    margin: 0px;
    max-width: none;
  }

  .payment-divider {
    padding: 25px 0px;
    text-align: center;
    color: var(--light-purple);
  }

  .add-payment-method {
    transition: all 0.3s ease-in-out;
    &--visible {
      transform: rotate(-180deg);
      transition: all 0.3s ease-in-out;
    }
  }
}

.payment-info-form {
  @media (max-width: 600px) {
    padding: 20px 0px 0px 0px;
    // height: 100%;
  }
}

.payment-info-header {
  @media (max-width: 600px) {
    padding: 0px 20px;
  }
}

.services-page {
  display: flex;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
    //overflow: scroll;

    .page-container {
      min-width: 300px;
      display: inline-table;
    }
  }

  @media (max-width: 300px) {
    .page-container {
      min-width: auto;
    }
  }

  .section-title {
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  @media (max-width: 600px) {
    .header {
      h2 {
        padding: 0px 10px;
      }
    }
  }
}

.services {
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.16);

  > div {
    max-width: 650px;
    padding-right: 50px;

    @media (max-width: 600px) {
      padding-right: 0px;
    }
  }

  @media (max-width: 600px) {
    padding: 22px 16px;
    height: auto;
    box-shadow: none;
  }
}

.profile-info {
  max-width: 322px;
  width: 100%;
  @media (max-width: 1050px) {
    .details {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      > span {
        flex-basis: auto;
      }
    }
  }

  @media (max-width: 900px) {
    .details,
    .header-component {
      padding: 20px;
    }
  }

  @media (max-width: 900px) {
    max-width: none;
  }

  @media (max-width: 600px) {
    max-width: none;
    .details {
      padding: 0px 20px;
    }
  }

  .avatar-section {
    margin-bottom: 16px;
    position: relative;

    &__image {
      border-radius: 50%;
      background-size: cover !important;
      background-color: #63637b;
      width: 96px;
      height: 96px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center !important;
    }
  }
}

.app-download-popup {
  width: 354px;
  border-radius: 24px;
  background-color: var(--primary-purple);
  padding: 32px;
  color: var(--white);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow:
    0px 6px 18px rgba(0, 0, 0, 0.12),
    0px 14px 20px rgba(0, 0, 0, 0.16);
  bottom: 40px;
  margin-left: 40px;

  @media (max-width: 600px) {
    width: 100%;
    border-radius: 0;
    bottom: 0px;
    margin-left: auto;
    text-align: center;
    position: fixed;

    h2 {
      font-size: 29px;
      line-height: 29px;
    }

    p {
      font-size: 17px;
    }

    button {
      font-size: 16px !important;
    }
  }

  @media (max-width: 350px) {
    padding: 20px;

    h2 {
      font-size: 27px !important;
      line-height: 40px !important;
    }
  }

  h2 {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 12px;
  }

  p {
    font-size: 18px;
    line-height: 27px;
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    box-shadow:
      0px 0px 1px rgba(0, 0, 0, 0.12),
      0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-top: 45px;
  }

  .input-field {
    margin-top: 26px;
    background: var(--white);
    border-radius: 50px;
    margin-bottom: 16px;
  }
}

.provider-slots {
  padding: 0px 24px 50px 24px;
  width: 100%;
  overflow: scroll;
  margin-top: 20px;

  h2 {
    font-size: 20px;
  }

  .slot-button {
    max-width: 103px;
  }
}

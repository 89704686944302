.service-block {
  font-size: 16px;

  @media (max-width: 600px) {
    padding: 16px 0px;
    padding-bottom: 24px;
    border-bottom: 1px solid #c9cad9;
    margin-bottom: 0px;

    &-name {
      font-size: 17px;
      margin-bottom: 0px;
    }

    &-text {
      color: var(--primary-purple);
    }
    &-price {
      font-size: 18px;
    }
  }

  &-name {
    width: 100%;
  }

  &-text {
    &--full {
    }
    &--summary {
      display: -webkit-box;
      max-height: 150px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .actions {
    display: flex;
  }
}

.daycare-modal-container {
  &-avatar {
    max-width: 125px;
  }

  .confirmation-screen {
    max-width: 450px;
    font-size: 18px;
  }

  button {
    width: 260px;
    margin: 0 auto;
    margin-top: 25px;
  }
}

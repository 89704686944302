.details {
  padding-bottom: 50px;

  .hours {
    @media (max-width: 1050px) {
      width: 330px;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    .day {
      min-width: 100px;
    }
  }
  @media (max-width: 600px) {
    padding: 5px 20px;
    margin-bottom: 20px;
    .mobile {
      font-size: 13px;
      color: var(--primary-purple);
    }
  }

  .hours-container {
    width: 100%;
    font-weight: 100;
    margin-top: 2px;
  }

  .static-map {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
  }
}

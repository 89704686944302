.request-appointment-modal-services {
  @media (max-width: 590px) {
    .service-block {
      border-bottom: none;
    }
  }

  &--mobile {
    @media (max-width: 600px) {
      max-height: 450px;
    }
  }

  .service-block {
    margin-bottom: 14px !important;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 20px;

    &-text--summary {
      -webkit-line-clamp: 2;
      max-height: 48px;
    }
  }
}

.modal-services-height {
  max-height: 300px;
}

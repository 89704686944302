.checkbox__container {
  display: flex;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 0px;

  &--consent {
    .checkmark {
      border: 1px solid var(--light-purple);
      box-shadow: inset 0 -3px 7px rgba(31, 29, 121, 0.1);
    }
  }

  &--round {
    .checkmark {
      border-radius: 100px;
    }
  }

  &--block {
    padding: 16px;
    border: 1px solid var(--light-purple);
    border-radius: 12px;
    background: #f4f4f9;
    justify-content: space-between;
    color: #63637b;

    .checkmark {
      border: 1px solid var(--light-purple);
    }
  }

  &--checked {
    background-color: #fffaf6 !important;
    border-color: var(--primary-orange) !important;
  }

  &--disabled {
    cursor: default;
    color: grey !important;
    .checkmark {
      opacity: 0.3;
    }
  }

  &--button {
    input:checked ~ .checkmark {
      background-color: var(--primary-orange) !important;
      border-color: var(--primary-orange);
    }
    .checkmark {
      min-width: 16px;
      max-width: 16px;
      height: 16px;
      border-color: var(--light-purple);

      &::after {
        width: 3px !important;
        height: 8px !important;
        border-width: 0 2.5px 2.5px 0 !important;
      }
    }
  }
}

.checkbox__container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border: 1px solid var(--black);
}

.checkbox__container input:checked ~ .checkmark {
  &--black {
    background-color: var(--black);
  }

  &--orange {
    border-color: var(--primary-orange);
    background-color: var(--primary-orange);
  }
}

.checkmark:after {
  content: '';
  display: none;
  margin-top: -2px;
}

.checkbox__container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox__container .checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 11px;
  border: solid var(--white);
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkbox__container {
  &--small {
    font-size: 17px;
  }
}

.select-button {
  border-radius: 12px;
  border: 1px solid var(--light-purple);
  background: #f4f4fa;
  padding: 16px 27px;
  text-align: center;
  cursor: pointer;
  color: var(--black);
  font-size: 18px;

  @media (max-width: 600px) {
    height: 52px !important;
  }

  &--small {
    padding: 0px 20px;
    height: 44px;
    font-size: 13px;
  }

  &--active {
    border: 1px solid var(--primary-orange);
    background: rgba(255, 152, 56, 0.05);
  }
}

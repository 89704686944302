.user-details {
  padding: 0px 5px;
  &-avatar {
    margin-right: 8px;
  }

  &-address {
    color: var(--primary-purple);
    font-size: 13px;
  }

  h4 {
    font-size: 17px;
  }
}

.modal-cards-loader {
  display: flex;
  flex-direction: column;

  span {
    background: linear-gradient(270deg, #d8d8e4, #f4f4fa, #d8d8e4);
    background-size: 400% 400%;
    animation: GradientAnimation 2s ease infinite;
  }

  .row {
    width: 100%;
    height: 110px;
    margin-top: 12px;
    background-size: 100% 100%;
    border-radius: 12px;

    .content {
      span {
        border-radius: 2px;
        background: linear-gradient(270deg, #d8d8e4, #f4f4fa, #d8d8e4);
        background-size: 400% 400%;
        animation: GradientAnimation 2s ease infinite;
        width: 57%;
        height: 12px;
      }

      .short {
        width: 60px;
      }

      .cta {
        width: 80px;
        display: block;
        margin-top: 12px;
      }
    }
  }
}

@keyframes GradientAnimation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

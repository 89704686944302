.provider-info-card {
  border-radius: 12px;
  border: 1px solid var(--light-purple);
  background: var(--white);
  overflow: hidden;
  margin-top: 32px;
  width: 100%;

  .static-map {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center;
  }

  .details-block {
    padding: 16px;
  }

  .border-top {
    border-top: 1px solid var(--light-purple);
  }
}

.loader {
  display: flex;
  flex-direction: column;

  span {
    background: linear-gradient(270deg, #d8d8e4, #f4f4fa, #d8d8e4);
    background-size: 400% 400%;
    animation: GradientAnimation 2s ease infinite;
  }

  .description {
    width: 100%;
    height: 12px;
    border-radius: 2px;
    margin-bottom: 11px;
    background: linear-gradient(270deg, #d8d8e4, #f4f4fa, #d8d8e4);
    background-size: 400% 400%;
    animation: GradientAnimation 2s ease infinite;
  }

  .row {
    width: 100%;
    height: 140px;
    margin-top: 12px;
    background-size: 100% 100%;
    border: 1px solid var(--light-purple);
    padding: 16px;
    border-radius: 6px;

    &-services {
      border: none;
      padding: 0;
    }

    &-avatar {
      min-width: 40px;
      height: 40px;
      border-radius: 50px;
      margin-right: 15px;
    }
  }
}

@keyframes GradientAnimation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

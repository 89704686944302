.default-payment-method {
  padding: 15px 15px 15px 6px;
  border: 2px solid var(--primary-orange);
  border-radius: 15px;
  background-color: #f4f4fa;

  &--disabled {
    border: 2px solid rgb(201, 201, 201);
  }

  .icon-card,
  .icon-visa,
  .icon-discover,
  .icon-amex,
  .icon-paypal {
    padding: 0px 10px;
  }
}

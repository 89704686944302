.booking-confirmation {
  width: 100%;

  &-desktop {
    height: calc(100vh - 72px);
    background: #fffaf6;

    &-container {
      background-color: var(--white);
      padding: 68px 118px;
      max-width: 756px;
      margin: 0 auto;
      box-shadow:
        0 9px 0px 0px white,
        0 -9px 0px 0px white,
        12px 0 15px -4px #0000001c,
        -12px 0 15px -4px #0000001c;

      @media screen and (max-width: 600px) {
        padding: 2rem;
      }

      h1 {
        font-size: 26px;
        margin-bottom: 8px;
      }

      &-price {
        border-top: 1px solid var(--light-purple);
      }
    }
  }
}

.download-app-button {
  height: 60px;
  font-size: 18px;
  padding: 20px 56px;
  background-color: transparent;
  color: var(--black);
  border: 1px solid #9191a8;
}

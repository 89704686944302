.payment-method-modal {
  .mobile-main-action-btn {
    border-top: none;
    padding: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: unset;
  }
  .mobile-page-container {
    padding: 0px;
  }
}

.input {
  &--white {
    .input-field {
      border: 1px solid var(--light-purple) !important;
      background: var(--white) !important;

      input {
        border: none !important;
      }
    }
  }

  &--small {
    .input-field {
      min-height: auto !important;
      height: 44px;
    }

    input {
      font-size: 13px !important;
      padding-left: 16px !important;
    }

    .currency {
      top: 12px !important;
      font-size: 13px;
    }

    .percentage {
      font-size: 13px;
    }
  }

  .input-field {
    min-height: 64px;
    border-radius: 12px;
    background: #f4f4fa;
    display: flex;
    align-items: center;
    border: none;
    font-size: 17px;

    &:focus-within {
      border: 1px solid #f89232;
    }

    &-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      .currency {
        position: absolute;
        top: 19px;
        left: 22px;
        font-weight: 100;
      }

      .input-component {
        width: inherit !important;
        background: none;
        border: none;
        font-size: 18px;
        padding: 17px 24px;
        border-radius: 12px;
        border: 1px solid #f4f4f9;
        font-weight: 100;

        &--disable-focus {
          padding-left: 0px;

          &:focus {
            border: none;
          }
        }

        &--with-currency {
          padding-left: 38px;
        }
      }
    }

    &__label {
      margin-bottom: 16px;
      margin-top: 0px;
      font-weight: bold;
      font-size: 18px;
    }

    button {
      position: relative;
      right: 16px;
    }
  }
}

.input-error {
  background: #fcf2f2;
  border: 1px solid #e82626;
}

.overflow-text {
  position: relative;
  &-button {
    background: none;
    border: none;
    padding: 0px;
    color: var(--primary-orange);
    font-size: 16px;
    font-weight: 100;
  }
}

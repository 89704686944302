.provider-service {
  padding: 24px;
  width: 100%;
  overflow: scroll;

  h2 {
    font-size: 20px;
  }

  .service-block {
    border-bottom: none;
  }

  .view-more {
    display: none;
  }
}

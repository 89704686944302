.header-component {
  @media (max-width: 600px) {
    margin: 0 auto;
    text-align: center;
    margin-top: 25px;
    border-bottom: 8px solid #f5f5fa;
    padding-bottom: 24px;
    width: 100%;

    .default-avatar {
      margin: 0 auto;
      margin-bottom: 8px;
    }
  }
}

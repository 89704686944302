.list-item {
  max-width: 420px;
  border: 2px solid #d3d3d3;
  border-radius: 10px;
  transition: border-color 0.3s ease;
  padding: 26px 20px;
  height: 76px;

  &--disabled {
    pointer-events: none;
    .default-avatar {
      opacity: 0.5;
    }
  }

  &--active {
    border-color: rgba(255, 152, 56, 1);
    background: rgba(255, 152, 56, 0.05);
  }
}
